// КОМПОНЕНТЫ БУТСТРАПА

import Dropdown from "../../node_modules/bootstrap/js/dist/dropdown.js";
import Modal from "../../node_modules/bootstrap/js/dist/modal.js";
import Offcanvas from "../../node_modules/bootstrap/js/dist/offcanvas.js";
import Collapse from "../../node_modules/bootstrap/js/dist/collapse.js";
import Tab from "../../node_modules/bootstrap/js/dist/tab.js";
import Toast from "../../node_modules/bootstrap/js/dist/toast.js";

window.bootstrap = { Dropdown, Offcanvas, Modal, Collapse, Tab, Toast };
